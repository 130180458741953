// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';
import { Environment } from '@app/interfaces';

export const environment: Environment = {
  production: false,
  version: env.npm_package_version,
  serverUrl: 'https://devapi.afaqydev.site/api/v1/',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'ar-SA'],
  // listImagesPath: 'https://dev.api.fleet.afaqy.co/',
  dummyDataLoader: false,
  // sentryPath: 'https://b397f986315b44d2bdf1b6d1bc6adf72@sentry.afaqy.co/3',
  // sentryEnvironmentName: 'Saas-Dev',
  // saasEnvironmentSubdomain: 'dev',
  // saasEnvironmentApiDomain: 'api.afaqy-fleet.site/api/v1/',
  // saasEnvironmentFrontDomain: 'afaqy-fleet.site',
  // pusher: {
  //   key: '796ec02b359ef98c86a4',
  //   options: {
  //     cluster: 'eu',
  //   },
  // },

  // local dev sentryPath
  // sentryPath: 'http://b54e15a188cd4c8d97a2f704c0382244@192.168.0.49:9000/8',
  heatmapsConfig: {
    // serverUrl: 'http://localhost:3333/api/',
    serverUrl: 'https://dev.api.heatmaps.afaqy.co/api',
    env: 'dev',
    project: 'afaqy-products-admin',
    userIdStorageKey: 'heatmapsUserId',
    userNameStorageKey: 'heatmapsUsername',
    submitActionsOnCount: 15,
    submitActionsOnInterval: 15000,
  },
  sensorStompConfig: {
    url: 'https://projects.afaqy.pro:12163/socket',
    headers: {},
    heartbeat_in: 0,
    heartbeat_out: 50000,
    reconnect_delay: 5000,
    debug: false,
  },
};
