import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ItemProps, ListOptions, ListParams, SystemPermission, SystemPermissions } from '@app/interfaces';
import { RootService } from '@app/core/root.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppHelper } from '@app/core/classes/app-helper';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { takeWhile } from 'rxjs/operators';
import { DummyData } from '@app/shared/services/dummy-data';
import { customEnvironment } from '@env/environment.custom';
import { I18nService } from '@app/core';
import { SharedService } from '@app/shared/services/shared.service';
import { TourService } from 'ngx-ui-tour-md-menu';
import { CoreListComponent as BaseCoreListComponent } from '@afaqyit/frontend-core';

@Component({
  selector: 'app-core-list',
  templateUrl: './core-list.component.html',
  styleUrls: ['./core-list.component.scss'],
})
export class CoreListComponent extends BaseCoreListComponent implements OnInit, OnDestroy {
  columns: ItemProps[];
  listOptions: ListOptions;
  params: ListParams;

  /**
   * local Props
   * Pre-init flags value
   */
  alive = true;
  appListGridOptions = true;
  showTitle = true;
  headerSmallSize = false;
  breadcrumbs: any[];
  constructor(
    public service: RootService,
    protected utilitiesService: UtilitiesService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected tourService?: TourService
  ) {
    // Please fill the super() call by the needed parameters
    super(service, utilitiesService, router, activatedRoute);
    this.listOptions = {
      itemsPerPage: [10, 20, 50, 100],
      archive: true,
      delete: false,
      add: true,
      edit: true,
      view: true,
      exportList: false,
      importList: false,
      clone: false,
      groups: false,
      hoverData: { status: false },
      selectable: false,
      activate: false,
      deactivate: false,
      archivedList: false,
      width_percent: false,
      showBreadcrumb: true,
      callListApi: true,
    };

    this.params = {
      queryParams: {
        page: 1,
        perPage: this.listOptions.itemsPerPage[0],
      },
    };
  }

  ngOnInit() {
    AppHelper.calcListHeight();
    AppHelper.pushResize();
    this.activatedRoute.data.pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (data.isArchive || this.listOptions.delete) {
        this.listOptions.deleteMultiple = true;
      }
      if (data && !data.isArchive) {
        this.listOptions.archivedList = false;
        this.listOptions.callListApi ? this.addQueryParams(false) : this.setFeatureProps();
      } else {
        this.listOptions.archivedList = true;
        this.listOptions.callListApi ? this.addQueryParams(true) : this.setFeatureProps();
      }
    });

    this.languageSubscription();
    this.service.loadSelectLists('list');
    this.service.setModulePermissions();
    this.permissionsUpdateSubscription();
    this.moduleTourSubscription();
  }

  addQueryParams(archivedList: boolean) {
    this.params = { queryParams: this.refactorFilters({ page: 1 }) };
    return this.service.startAutoLoad(this.params, archivedList).then(() => {
      this.setFeatureProps();
    });
  }

  setFeatureProps() {
    const colsFromService: ItemProps[] = this.service.featureProps;
    if (colsFromService.length) {
      this.columns = colsFromService;
    }
    this.paginationInit();
  }

  languageSubscription() {
    this.service.shared.sendLanguage.pipe(takeWhile(() => this.alive)).subscribe((lang: string) => {
      // this.setDatepickerLang(lang);
      this.service.updateResources.next(true);
    });
  }
  /**
   * Init sort observables
   */
  // todo: Refactor pagination and filters to isolate the modules (if you are using two modules on the same page)
  paginationInit() {
    this.utilitiesService.filtersObservable$.pipe(takeWhile(() => this.alive)).subscribe((filtersData: any) => {
      if (filtersData) {
        filtersData = this.service.refactorFiltersBeforeQueryParams(filtersData);
        const filters = {};
        for (const key of Object.keys(filtersData)) {
          if (Array.isArray(filtersData[key])) {
            filters[key + '[]'] = filtersData[key];
          } else {
            filters[key] = filtersData[key];
          }
          if (!filtersData[key]) {
            delete filters[key];
          }
        }
        this.params.queryParams = this.refactorFilters(filters);
        return this.resetList();
      }
    });

    this.utilitiesService.sortObservable$.pipe(takeWhile(() => this.alive)).subscribe((sortData: any) => {
      if (sortData.colName) {
        this.params.queryParams.sort = sortData.colName;
        this.params.queryParams.direction = sortData.dir ? 'asc' : 'desc';
        return this.resetList();
      }
    });

    /**
     * Retrieve the item count state from the pagination service
     */
    this.utilitiesService.countObservable$.pipe(takeWhile(() => this.alive)).subscribe((itemCount) => {
      if (itemCount) {
        this.params.queryParams.page = 1;
        this.params.queryParams.perPage = +itemCount;
        return this.loadResources();
      }
    });

    /**
     * refresh resources list
     */
    this.service.updateResources.pipe(takeWhile(() => this.alive)).subscribe(() => {
      return this.loadResources();
    });

    /**
     * Append Resources list
     */
    this.service.appendResources$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      return this.loadAppendResources();
    });

    /**
     * Retrieve the page number state from the pagination service
     */
    this.utilitiesService.paginateObservable$.pipe(takeWhile(() => this.alive)).subscribe((res) => {
      if (res.page && res.cid === this.service.cid) {
        this.params.queryParams.page = res.page;
        return this.loadResources();
      }
    });

    /**
     * Retrieve the page number state from the pagination service
     */
    this.utilitiesService.paginateInfiniteObservable$
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: { page: number; cid?: string; customCid?: string; serviceUniqueIdentifier?: string }) => {
        if (
          event.page &&
          (event.cid === this.service.cid || (event.customCid && event.customCid === this.service.customCid))
        ) {
          if (event.serviceUniqueIdentifier) {
            if (event.serviceUniqueIdentifier !== this.service.customData.serviceUniqueIdentifier) {
              return;
            } else {
              this.params.queryParams.page = event.page;
              return this.loadInfiniteResources();
            }
          } else {
            this.params.queryParams.page = event.page;
            return this.loadInfiniteResources();
          }
        }
      });
  }

  paginationInitInfinite() {
    this.utilitiesService.filtersObservable$.pipe(takeWhile(() => this.alive)).subscribe((filtersData: any) => {
      if (filtersData) {
        const filters = {};
        for (const key of Object.keys(filtersData)) {
          if (Array.isArray(filtersData[key])) {
            filters[key + '[]'] = filtersData[key];
          } else {
            filters[key] = filtersData[key];
          }
          if (!filtersData[key]) {
            delete filters[key];
          }
        }
        this.params.queryParams = this.refactorFilters(filters);
        return this.resetList();
      }
    });

    // this.utilitiesService.sortObservable$.pipe(takeWhile(() => this.alive)).subscribe((sortData: any) => {
    //   if (sortData.colName) {
    //     this.params.queryParams.sort = sortData.colName;
    //     this.params.queryParams.direction = sortData.dir ? 'asc' : 'desc';
    //     return this.resetList();
    //   }
    // });

    /**
     * Retrieve the item count state from the pagination service
     */
    // this.utilitiesService.countObservable$.pipe(takeWhile(() => this.alive)).subscribe(itemCount => {
    //   if (itemCount) {
    //     this.params.queryParams.page = 1;
    //     this.params.queryParams.perPage = +itemCount;
    //     return this.loadResources();
    //   }
    // });

    /**
     * refresh resources list
     */
    this.service.updateResources.pipe(takeWhile(() => this.alive)).subscribe(() => {
      return this.loadResources();
    });

    /**
     * Append Resources list
     */
    this.service.appendResources$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      return this.loadAppendResources();
    });

    /**
     * Retrieve the page number state from the pagination service
     */
    // this.utilitiesService.paginateObservable$.pipe(takeWhile(() => this.alive)).subscribe(page => {
    //   if (page) {
    //     this.params.queryParams.page = page;
    //     return this.loadResources();
    //   }
    // });

    /**
     * Retrieve the page number state from the pagination service
     */
    this.utilitiesService.paginateInfiniteObservable$
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: { page: number; cid?: string; customCid?: string; serviceUniqueIdentifier?: string }) => {
        if (event.page && (event.cid === this.service.cid || event.customCid === this.service.customCid)) {
          if (event.serviceUniqueIdentifier) {
            if (event.serviceUniqueIdentifier !== this.service.customData.serviceUniqueIdentifier) {
              return;
            } else {
              this.params.queryParams.page = event.page;
              return this.loadInfiniteResources();
            }
          } else {
            this.params.queryParams.page = event.page;
            return this.loadInfiniteResources();
          }
        }
      });
  }

  /**
   * Reset list to page 1
   * Refresh lists
   */
  resetList() {
    this.params.queryParams.page = 1;
    this.utilitiesService.navigate(this.params.queryParams.page, this.service.cid);
  }

  /**
   * fetch list data from servicee
   */
  loadResources() {
    if (this.listOptions.archivedList) {
      return this.service.startAutoLoad(this.params, true);
    } else {
      return this.service.startAutoLoad(this.params);
    }
  }

  /**
   * fetch list data from service
   */
  loadAppendResources() {
    const params = { ...this.params };
    params.queryParams.page = 1;
    if (this.listOptions.archivedList) {
      return this.service.startAutoLoad(params, true, true, true);
    } else {
      return this.service.startAutoLoad(params, false, true, true);
    }
  }

  /**
   * fetch list data from service
   */
  loadInfiniteResources() {
    if (this.listOptions.archivedList) {
      return this.service.startAutoLoad(this.params, true, true);
    } else {
      return this.service.startAutoLoad(this.params, false, true);
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.service.clearSelectedList();
    this.utilitiesService.setFilters({});
    this.utilitiesService.sortListBy({});
    this.service.showInlineFilters = false;
  }

  permissionsUpdateSubscription() {
    this.service.permissionsService.permissionsUpdated.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.service.setModulePermissions();
      this.unAuthRedirect();
    });
  }

  unAuthRedirect() {
    const permissionsKeys = this.activatedRoute.snapshot.data.permissionsKeys;
    if (permissionsKeys && permissionsKeys.length) {
      if (
        !permissionsKeys.every((permissionKey: { module?: string; key?: string }) => {
          return this.service.permissionsService.getPermissions(permissionKey.module, permissionKey.key);
        })
      ) {
        this.router.navigateByUrl('/401', { skipLocationChange: true });
      }
    }
  }

  /**
   * generic method to be overridden in specific extended components to refactor filters in each component as needed
   * @param filters passed filters object
   */
  refactorFilters(filters: any) {
    return this.service.refactorFilters(filters);
  }

  moduleTourSubscription() {
    this.service.shared.isModuleTour.pipe(takeWhile(() => this.alive)).subscribe((res) => {
      if (this.listOptions.tourSteps) {
        this.tourService.initialize(this.listOptions.tourSteps);
        this.tourService.start();
      }
    });
  }

  // TO-TO : This function should be implemented here ,then be passed (as @Input) to all Childs that may use it.
  hasFilters(): boolean {
    let count = 0;
    if (this.columns && this.columns.length) {
      this.columns.forEach((c) => {
        if (c.list) {
          if (c.list.searchable) {
            count++;
          }
        }
      });
    }
    return count > 0;
  }
}
